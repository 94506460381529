import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { EventService } from '../../core/services/event.service';
import { Router, NavigationEnd } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

import { MENU1,MASTERS,MENU2,MENU3 } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers:[NgxSpinnerService]
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;

  menuItems = [];
  privileges =[];

  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(private eventService: EventService, 
    public spinner:NgxSpinnerService,
    private router: Router, public translate: TranslateService, private http: HttpClient) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
       
      }
    });

    
  }

   ngOnInit() {
   
    
    this.getPrivileges(); //Show the sidebar menuitems based on the userpermissions

    this.initialize();


   
    document.body.setAttribute('data-sidebar', 'light');
    this._scrollElement();
  }

  getPrivileges(){
    //Show the sidebar menuitems based on the userpermissions

    // console.log("getPrivileges Function");
    this.spinner.show();
    this.privileges = sessionStorage.getItem('privileges')?.split(',') || [];
    let userTypeId = +sessionStorage.getItem('usertypeId');

    let MENU; 
    // if(userTypeId === 1){
    //   MENU = MASTERS;
    // }
    // else{
      MENU = MENU1;
     
    // }

    // console.log("privilegemodules",sessionStorage.getItem('privilegemodules') );
    // console.log("privileges",sessionStorage.getItem('privileges') );
    // console.log("usertypeId",sessionStorage.getItem('usertypeId') );
    let usertypeAdmin = sessionStorage.getItem('usertypeId');
    

    // console.log("sidebar privileges "+this.privileges);
    // console.log("MENU",MENU);
   

    if(this.privileges.indexOf('4') > -1 ){
      //Disburse Request List
      // let index = MENU.findIndex(x=>x.link?.includes("/tables/disbursementlist"));
      let index = MENU.findIndex(x=>x.pkeyId?.includes("4"));
      MENU[index].visible = true;
      // console.log("menu "+MENU[index].link)

    }
    if(this.privileges.indexOf('1') > -1){
      //Expense Request List

      let index = MENU.findIndex(x=>x.pkeyId?.includes("1"));
      MENU[index].visible = true;
      // console.log("/tables/expenserequest "+index+" "+this.privileges.indexOf('1'))
    }

  //  if(this.privileges.indexOf('12')===-1){
   
  //       let index = MENU.findIndex(x=>x.link?.includes("/tables/quotationlist"));
  //       MENU[index].visible = false;
  //       console.log("/tables/quotationlist "+index+" "+this.privileges.indexOf('12'))
  //    }
   if(this.privileges.indexOf('5') > -1){
      //Supplier Requests List
        let index = MENU.findIndex(x=>x.pkeyId?.includes("5"));
        MENU[index].visible = true;
        // console.log("/tables/suppliers "+index+" "+this.privileges.indexOf('1'))
     }
   if(this.privileges.indexOf('9') > -1){
      ///OLD Purchase Indent List
        let index = MENU.findIndex(x=>x.pkeyId?.includes("9"));
        MENU[index].visible = true;
        // console.log("/tables/suppliers "+index+" "+this.privileges.indexOf('1'))
     }
      
     if(usertypeAdmin==='1'){
      ///OLD Purchase Indent List
        let index = MENU.findIndex(x=>x.pmkeyId?.includes("MAS"));
        // console.log("MASTER index",index);
        MENU[index].visible = true;
        // console.log("/tables/suppliers "+index+" "+this.privileges.indexOf('1'))
     }

     if(this.privileges.indexOf('18') > -1){
      // console.log("18");
      //Purchase Order List
        let index = MENU.findIndex(x=>x.pkeyId?.includes("18"));
        MENU[index].visible = true;
       
     }
     if(this.privileges.indexOf('21') > -1){
      // console.log("21");
      //GRN List
        let index = MENU.findIndex(x=>x.pkeyId?.includes("21"));
        MENU[index].visible = true;    
     }
     if(this.privileges.indexOf('24') > -1){
      // console.log("24");
      //Inventory List
        let index = MENU.findIndex(x=>x.pkeyId?.includes("24"));
        MENU[index].visible = true;
       
     }
     if(this.privileges.indexOf('25') > -1){
      // console.log("25");
      //Reports 
        let index = MENU.findIndex(x=>x.pkeyId?.includes("25"));
        MENU[index].visible = true;
       
     }
     if(this.privileges.indexOf('26') > -1){
      // console.log("26");
      //Reports 
        let index = MENU.findIndex(x=>x.pkeyId?.includes("26"));
        MENU[index].visible = true;
       
     }
     if(this.privileges.indexOf('27') > -1){
      // console.log("27");
      //Reports 
        let index = MENU.findIndex(x=>x.pkeyId?.includes("27"));
        MENU[index].visible = true;
       
     }

  //  if(this.privileges.indexOf('9')===-1 && this.privileges.indexOf('10')===-1){
    
  //       let index = MENU.findIndex(x=>(x.label?.includes("Purchase Indent") && x.isTitle===true));
  //       MENU[index].visible = false;
  //       index = MENU.findIndex(x=>x.link?.includes("/tables/purchaseindent")) ;
  //       MENU[index].visible = false;
  //       index = MENU.findIndex(x=>x.link?.includes("/tables/addpurchaseindent")) ;
  //       MENU[index].visible = false;
  //       console.log("/tables/purchase "+index+" "+this.privileges.indexOf('10'))
  //    }
  //    else if(this.privileges.indexOf('9')===-1){
  //     let index = MENU.findIndex(x=>x.link?.includes("/tables/purchaseindent")) ;
  //     MENU[index].visible = false;
 
  //    }
  //   else  if(this.privileges.indexOf('10')===-1){
  //     let index = MENU.findIndex(x=>x.link?.includes("/tables/addpurchaseindent")) ;
  //     MENU[index].visible = false;

  //    }
    // console.log('sidebar menu'+ this.privileges+" "+this.privileges.indexOf('1'));
    this.spinner.hide();
   
  }
  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
        if(this.scrollRef.SimpleBar.getScrollElement() !== null)
          this.scrollRef.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Light sidebar
   */
  lightSidebar() {
    document.body.setAttribute('data-sidebar', 'light');
    // document.body.setAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-layout-scrollable');
  }

  /**
   * Compact sidebar
   */
  compactSidebar() {
    document.body.setAttribute('data-sidebar-size', 'small');
    // document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-layout-scrollable');
  }

  /**
   * Icon sidebar
   */
  iconSidebar() {
    document.body.classList.add('sidebar-enable');
    document.body.classList.add('vertical-collpsed');
    // document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-scrollable');
  }

  /**
   * Boxed layout
   */
  boxedLayout() {
    document.body.setAttribute('data-keep-enlarged', 'true');
    document.body.setAttribute('data-layout-size', 'boxed');
    // document.body.setAttribute('data-sidebar', 'dark');
    document.body.classList.add('vertical-collpsed');
    document.body.classList.remove('sidebar-enable');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-scrollable');
  }

  /**
   * Colored sidebar
   */
  coloredSidebar() {
    document.body.setAttribute('data-sidebar', 'colored');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-scrollable');
  }

  scrollable() {
    document.body.setAttribute('data-layout-scrollable', 'true');
    document.body.removeAttribute('data-sidebar');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["hash"].replace('#',''));
      // console.log("menu links aa "+links[i]["hash"].replace('#',''));
      
    }
    var itemIndex = paths.indexOf(window.location.hash.replace('#',''));
    // console.log("window path "+window.location.hash.replace('#',''));
    if (itemIndex === -1) {
      const strIndex = window.location.hash.replace('#','').lastIndexOf('/');
      const item = window.location.hash.replace('#','').substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
      // console.log("menu item"+strIndex+" "+item+" "+menuItemEl);
    } else {
      menuItemEl = links[itemIndex];
      // console.log("menu item"+itemIndex+" "+menuItemEl);
    }
    if (menuItemEl) {
      // console.log("menu item el"+menuItemEl)
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      // console.log("parent el"+parentEl.tagName)
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }

  }

  /**
   * Initialize
   */
  initialize(): void {
    // console.log("initialize function");

    let userTypeId = +sessionStorage.getItem('usertypeId');
    // console.log(userTypeId+" user type id");
    // if(userTypeId == 1){
     
    //   this.menuItems = MASTERS;
    // }
    // else {
      this.menuItems = MENU1;

    // }
    
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
