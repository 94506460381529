import { MenuItem } from './menu.model';


//All Users Except Admin
export const MENU1: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:true

    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:true
    },
    // {
    //     id: 3,
    //     label: 'Expense Request ',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/expenserequest',
    //     visible:false,
    //     pkeyId:'1',
    //     pmkeyId:'1',
        
    // },    
    {
        id: 3,
        label: 'Expense Request',
        icon: 'mdi mdi-cash-multiple',
        link:'/tables/explist',
        visible:false,
        pkeyId:'1',
        pmkeyId:'1',
        
    },    
    // {
    //     id: 4,
    //     label: 'Purchase Indent ',
    //     isTitle: true,
    //     visible:false,
    //     pkeyId:'9',
    //     pmkeyId:'4',
    // },
    // {
    //     id:5,
    //     label: 'Add Purchase Indent',
    //     icon: 'bx-store',
    //     link:'/tables/addpurchaseindent',
    //     visible:false
        
    // },
    {
        id: 6,
        label: 'Purchase Indent',
        icon: 'mdi mdi-cart',
        link:'/tables/purchaseindent',
        visible:false,
        pkeyId:'9',
        pmkeyId:'4',
        
    },
    {
        id: 7,
        label: 'Disburse Request',
        icon: 'mdi mdi-cash-multiple',
        link:'/tables/disbursementlist',
        visible:false,
        pkeyId:'4',
        pmkeyId:'1',
        
    },
    {
        id: 8,
        label: 'Suppliers',
        // icon: 'mdi mdi-truck-delivery',
        icon: 'mdi mdi-clipboard-account-outline',
        link:'/tables/suppliers',
        visible:false,
        pkeyId:'5',
        pmkeyId:'2',
    },

    {
        id:9,
        label: 'Masters',
        icon: 'bx bxs-dashboard',
        visible:false,
        pmkeyId:'MAS',
        subItems: 
        [
            {
                id: 234,
                label: 'Site',
                link: '/tables/sites',
                parentId: 1,
                visible:true
            },
            {
                id: 233,
                label: 'Site Types',
                link: '/tables/sitetypes',
                parentId: 1,
                visible:true
            },
            {
                id: 235,
                label: 'Expense Head',
                link: '/tables/expensehead',
                parentId: 1,
                visible:true
            },
            // {
            //     id: 236,
            //     label: 'Supplier',
            //     link: '/tables/supplier',
            //     parentId: 1,
            //     visible:true
            // },
            {
                id: 245,
                label: 'Supplier Category',
                link: '/tables/suppliercategory',
                parentId: 1,
                visible:true
            }, {
                id: 246,
                label: 'Supplier Type',
                link: '/tables/suppliertype',
                parentId: 1,
                visible:true
            },
            {
                id: 237,
                label: 'User Types',
                link: '/tables/usertype',
                parentId: 1,
                visible:true
            },
            {
                id: 238,
                label: 'Account User',
                link: '/tables/accountuser',
                parentId: 1,
                visible:true
            },
            {
                id: 239,
                label: 'Payment Type',
                link: '/tables/paymentoption',
                parentId: 1,
                visible:true
            },
            {
                id: 240,
                label: 'Documents',
                link: '/tables/documents',
                parentId: 1,
                visible:true
            },
            {
                id: 241,
                label: 'Brands',
                link: '/tables/brands',
                parentId: 1,
                visible:true
            },
            {
                id: 242,
                label: 'Item Category',
                link: '/tables/itemcategory',
                parentId: 1,
                visible:true
            },
            {
                id: 243,
                label: 'UOM',
                link: '/tables/uom',
                parentId: 1,
                visible:true
            },
            {
                id: 244,
                label: 'Items',
                link: '/tables/items',
                parentId: 1,
                visible:true

            },
            {
                id: 501,
                label: 'Billingtype',
                link: '/tables/billingtype',
                parentId: 1,
                visible:true
            },
            {
                id: 502,
                label: 'Packagetype',
                link: '/tables/packagetype',
                parentId: 1,
                visible:true
            },
            
           
            {
                id: 268,
                label: 'Location',
                link: '/tables/location',
                parentId: 1,
                visible:true

            },
            // {
            //     id: 266,
            //     label: 'Privileges',
            //     link: '/tables/privileges',
            //     parentId: 1,
            //     visible:true

            // }
            
            
        ]


    },
    {
        id: 10,
        label: 'Purchase Order',
        icon: 'mdi mdi-cart-check',
        link:'/tables/purchaseorderlist',
        visible:false,
        pkeyId:'18',
        pmkeyId:'6', //live
        // pmkeyId:'7', local
    },
    {
        id: 11,
        label: 'Goods Received Note',
        icon: 'mdi mdi-cart-check',
        link:'/tables/grnlist',
        visible:false,
        pkeyId:'21',
        pmkeyId:'7', //live
        
    },
    {
        id: 12,
        label: 'Inventory List',
        icon: 'mdi mdi-clipboard-list-outline',
        link:'/tables/inventorylist',
        visible:false,
        pkeyId:'24',
        pmkeyId:'8', //live
        // pmkeyId:'7', local
    },
    {
        id: 14,
        label: 'Purchase Request',
        icon: 'mdi mdi-file-document-edit',
        link:'/tables/purchaserequestlist',
        visible:false,
        // pkeyId:'9',
        // pmkeyId:'4',
        
    },
    {
        id: 15,
        label: 'System Settings ',
        icon: 'mdi mdi-cog-outline',
        // link:'/tables/purchaserequestlist',
        visible:false,
        pmkeyId:'10',
        pkeyId:'27',
        subItems:[
            {
                id: 267,
                label: 'Privilege Module',
                link: '/tables/privilegemodules',
                parentId: 1,
                visible:true


            },
            
        ]
       
        
    },
    {
        id: 16,
        label: 'Settings ',
        icon: 'mdi mdi-cogs',
        // link:'/tables/purchaserequestlist',
        visible:false,
        pmkeyId:'10',
        pkeyId:'26',
        subItems:[
            {
                id: 264,
                label: 'Permission View',
                link: '/tables/permissionview',
                parentId: 1,
                visible:true

            },
            {
                id: 265,
                label: 'Add Approval Setting',
                link: '/tables/approvalsettings',
                parentId: 1,
                visible:true

            },
            {
                id: 266,
                label: 'Approval Setting List',
                link: '/tables/approvallist',
                parentId: 1,
                visible:true

            },
        ]
       
        
    },
    {
        id: 10,
        label: 'Reports',
        icon: 'bx bxs-report',
        link:'/tables/report-yearlist',
        visible:false,
        pkeyId:'25', //Live
        pmkeyId:'9', //Live
       
    },
    // {
    //     id: 18,
    //     label: 'Disburse List (Test)',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/disburselist',
    //     visible:true,
    //     // pkeyId:'4',
    //     // pmkeyId:'1',
        
    // },
    // {
    //     id: 19,
    //     label: 'Advanced List (Test)',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/advanced',
    //     visible:true,
    //     // pkeyId:'4',
    //     // pmkeyId:'1',
        
    // },
    // {
    //     id: 20,
    //     label: 'Expense List (Test)',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/expenselist',
    //     visible:true,
    //     // pkeyId:'4',
    //     // pmkeyId:'1',
        
    // },
    // {
    //     id: 20,
    //     label: 'Expense List (OLD)',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/expenserequest',
    //     visible:true,
    //     // pkeyId:'4',
    //     // pmkeyId:'1',
        
    // },
    // {
    //     id: 21,
    //     label: 'Test List (Test)',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/testlist',
    //     visible:true,
    //     // pkeyId:'4',
    //     // pmkeyId:'1',
        
    // },
    // {
    //     id: 22,
    //     label: 'Exp List',
    //     icon: 'bx bx-list-ul',
    //     link:'/tables/explist',
    //     visible:true,
    //     // pkeyId:'1',
    //     // pmkeyId:'1',
        
    // },
    // { ---  last work in progress page----
    //     id: 23,
    //     label: 'Supplier List',
    //     icon: 'mdi mdi-clipboard-account-outline',
    //     link:'/tables/supplierlistview',
    //     visible:true,
    //     // pkeyId:'4',
    //     // pmkeyId:'1',
        
    // },
    // {
    //     id: 17,
    //     label: 'Reports ',
    //     icon: 'mdi mdi-cogs',
    //     // link:'/tables/purchaserequestlist',
    //     visible:true,
    //     pmkeyId:'SETT',
    //     subItems:[
    //         {
    //             id: 301,
    //             label: 'ExpenseWise Report',
    //             link: '/tables/report-yearlist?rtype=expensewise',
    //             // parentId: 1,
    //             visible:true

    //         },
    //         {
    //             id: 302,
    //             label: 'SiteWise Report',
    //             link: '/tables/report-yearlist?rtype=sitewise',
    //             // parentId: 1,
    //             visible:true

    //         },
            
    //     ]
       
        
    // }
    
    
];


export const MENU2: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard'
    },

];


export const SETTINGS : MenuItem[] =[


    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:true
    },
   
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:true
    },
    
    {
        id:232,
        label: 'Settings',
        icon: 'bx bxs-dashboard',
        visible:true,
        subItems: 
        [
            {
                id: 234,
                label: 'Privileges',
                link: '/tables/privileges',
                parentId: 1,
                visible:true
            },
            {
                id: 233,
                label: 'Privilege Module',
                link: '/tables/privilegemodules',
                parentId: 1,
                visible:true
            },
            
            
            
        ]


    },
    {
        id: 245,
        label: 'Purchase Indent',
        isTitle: true,
        visible:true
    },
    {
        id: 246,
        label: 'Add Purchase Indent',
        icon: 'bx-store',
        link:'/tables/addpurchaseindent',
        visible:true
        
    },
    {
        id: 247,
        label: 'Purchase Request',
        icon: 'mdi mdi-cart',
        link:'/tables/purchaseindent',
        visible:true
        
    },
    {
        id: 248,
        label: 'Disburse Request',
        icon: 'mdi mdi-cash-multiple',
        link:'/tables/disbursementlist',
        visible:true
        
    },
];


export const MASTERS : MenuItem[] =[


    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:true
    },
   
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:true
    },
    {
        id: 174,
        label: 'Expense Request',
        icon: 'mdi mdi-file-document-edit',
        link:'/tables/expenserequest',
        visible:true
        
    },
    {
        id:232,
        label: 'Masters',
        icon: 'bx bxs-dashboard',
        visible:true,
        subItems: 
        [
            {
                id: 234,
                label: 'Site',
                link: '/tables/sites',
                parentId: 1,
                visible:true
            },
            {
                id: 233,
                label: 'Site Types',
                link: '/tables/sitetypes',
                parentId: 1,
                visible:true
            },
            {
                id: 235,
                label: 'Expense Head',
                link: '/tables/expensehead',
                parentId: 1,
                visible:true
            },
            {
                id: 236,
                label: 'Supplier',
                link: '/tables/supplier',
                parentId: 1,
                visible:true
            },
            {
                id: 245,
                label: 'Supplier Category',
                link: '/tables/suppliercategory',
                parentId: 1,
                visible:true
            }, {
                id: 246,
                label: 'Supplier Type',
                link: '/tables/suppliertype',
                parentId: 1,
                visible:true
            },
            {
                id: 237,
                label: 'User Types',
                link: '/tables/usertype',
                parentId: 1,
                visible:true
            },
            {
                id: 238,
                label: 'Account User',
                link: '/tables/accountuser',
                parentId: 1,
                visible:true
            },
            {
                id: 239,
                label: 'Payment Type',
                link: '/tables/paymentoption',
                parentId: 1,
                visible:true
            },
            {
                id: 240,
                label: 'Documents',
                link: '/tables/documents',
                parentId: 1,
                visible:true
            },
            {
                id: 241,
                label: 'Brands',
                link: '/tables/brands',
                parentId: 1,
                visible:true
            },
            {
                id: 242,
                label: 'Item Category',
                link: '/tables/itemcategory',
                parentId: 1,
                visible:true
            },
            {
                id: 243,
                label: 'UOM',
                link: '/tables/uom',
                parentId: 1,
                visible:true
            },
            {
                id: 244,
                label: 'Items',
                link: '/tables/items',
                parentId: 1,
                visible:true

            },
            {
                id: 264,
                label: 'Permission View',
                link: '/tables/permissionview',
                parentId: 1,
                visible:true

            },
            {
                id: 265,
                label: 'Add Approval Setting',
                link: '/tables/approvalsettings',
                parentId: 1,
                visible:true

            },
            {
                id: 266,
                label: 'Approval Setting List',
                link: '/tables/approvallist',
                parentId: 1,
                visible:true

            },
            {
                id: 267,
                label: 'Privilege Module',
                link: '/tables/privilegemodules',
                parentId: 1,
                visible:true

            },
            // {
            //     id: 266,
            //     label: 'Privileges',
            //     link: '/tables/privileges',
            //     parentId: 1,
            //     visible:true

            // }
            
            
        ]


    },
    {
        id: 301,
        label: 'Purchase Indent',
        isTitle: true,
        visible:true
    },
    {
        id: 246,
        label: 'Add Purchase Indent',
        icon: 'bx-store',
        link:'/tables/addpurchaseindent',
        visible:true
        
    },
    {
        id: 302,
        label: 'Purchase Request',
        icon: 'mdi mdi-cart',
        link:'/tables/purchaseindent',
        visible:true
        
    },
    {
        id: 303,
        label: 'Disburse Request',
        icon: 'mdi mdi-cash-multiple',
        link:'/tables/disbursementlist',
        visible:true
        
    },
    {
        id: 304,
        label: 'Suppliers',
        icon: 'mdi mdi-truck-delivery',
        link:'/tables/suppliers',
        visible:true
    },

    // {
    //     id: 305,
    //     label: 'Quotation',
    //     icon: 'bx bx-copy-alt',
    //     link:'/tables/quotationlist',
    //     visible:true
    // },
];


export const MENU3: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:false
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:false
    },
    {
        id: 174,
        label: 'Expense Request',
        icon: 'mdi mdi-file-document-edit',
        link:'/tables/expenserequest',
        visible:false
        
    },    
    // {
    //     id: 175,
    //     label: 'Purchase Indent',
    //     isTitle: true,
    //     visible:false
    // },
    // {
    //     id: 178,
    //     label: 'Add Purchase Indent',
    //     icon: 'bx-store',
    //     link:'/tables/addpurchaseindent',
    //     visible:false
        
    // },
    // {
    //     id: 179,
    //     label: 'Purchase Request',
    //     icon: 'mdi mdi-cart',
    //     link:'/tables/purchaseindent',
    //     visible:false
        
    // },
    // {
    //     id: 188,
    //     label: 'Disburse Request',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/disbursementlist',
    //     visible:false
        
    // },
    // {
    //     id: 189,
    //     label: 'Suppliers',
    //     icon: 'mdi mdi-truck-delivery',
    //     link:'/tables/suppliers',
    //     visible:false
    // },


];


